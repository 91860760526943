import {ThemeProvider} from "styled-components";
import MoonIcon from "./componentes/icons/MoonIcon";
import SunIcon from "./componentes/icons/SunIcon";
import Switch from "./componentes/Switch";
import { SearchBar } from "./componentes/SearchBar";
import styled from "styled-components";
import {Styles} from "./componentes/Styles";
import UpDown from "./pages/UpDown";


const StyledApp = styled.div`
max-width: 100%;
background-color:${props => props.theme.body};    
`;
// Estilos personalizados:
// const texsec={ color: `${theme.texsec} `};
// const bar ={  color: `${theme.cardtext}`, 
//                  backgroundColor: `${theme.barbg}`}; 
  function ToggleTheme() {
    const {lightTheme, Darktheme,toggle,isDarktheme } = Styles('dark')
    // const [theme, Settheme]=useState('dark');
    // console.log(theme)
    // const isDarktheme = theme ==='light';
    // const toggle =()=>{
    //   Settheme(isDarktheme?"dark":"light")
    // }
    
  return (
    <ThemeProvider theme={isDarktheme ?Darktheme :lightTheme}>
    <StyledApp>
        <div className="stictheme">
          <SunIcon theme={isDarktheme ?Darktheme.fill :lightTheme.fill}/>
          <Switch  toggle={toggle} />
          <MoonIcon theme={isDarktheme ?Darktheme.fill :lightTheme.fill}/>
        </div>
        <UpDown/>
    <SearchBar theme={isDarktheme ?Darktheme :lightTheme}/>
    </StyledApp>
    </ThemeProvider>
  )
}

export default ToggleTheme;
