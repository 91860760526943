import { useTheme } from "styled-components";  // para usar temas creados
import InstaIcon from "../icons/InstaIcon";
import FacebIcon from "../icons/FacebIcon";
import TikTokIcon from "../icons/TiktokIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import { navbar } from "@bootstrap-styled/css-mixins/lib/navbar";
export const Footer=()=>{

    const theme = useTheme(); 
    const bar = { color: `${theme.cardtext}`, backgroundColor: `${theme.barbg}` };


    return(
        <>
        {/* <!-- Footer --> */}
    <footer className="text-center text-lg-start bg-body-tertiary text-muted" style={bar}>
    {/* <!-- Section: Social media --> */}
    <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom" style={bar}>
        {/* <!-- Left --> */}
        <a href="https://www.iubenda.com/privacy-policy/37458697" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">
        Politicas de privacidad
        </a>
        <div className="me-5 d-none d-lg-block" style={bar}>
        <span>Conecta con nuestras redes sociales:</span>
        </div>
        {/* <!-- Left --> */}

        {/* <!-- Right --> */}
        <div style={bar}>
        <a href="https://www.tiktok.com/@esto_ess?_t=8ip1iNqdyMP&_r" className="me-4 text-reset" target="_blank"  rel="noopener noreferrer">
            <i>{TikTokIcon('20px','20px')}</i>
        </a>
        <a href="https://youtube.com/@Estooess?si=ovah3lGH-iwpluO7" className="me-4 text-reset" target="_blank"  rel="noopener noreferrer">
            <i>{YoutubeIcon('20px','20px')}</i>
        </a>
        <a href="https://www.instagram.com/estoo_ess/" className="me-4 text-reset" target="_blank"  rel="noopener noreferrer">
            <i>{InstaIcon('20px','20px')}</i>
        </a>
        <a href="https://www.facebook.com/BasicasSoluciones?mibextid=ZbWKwL" className="me-4 text-reset" target="_blank"  rel="noopener noreferrer">
            <i>{FacebIcon('20px','20px')}</i>
        </a>
        </div>
        {/* <!-- Right --> */}
    </section>
    {/* <!-- Section: Social media --> */}
    {/* <!-- Section: Links  --> */}
    <section className="" style={bar}>
        <div className="container text-center text-md-start mt-5">
        {/* <!-- Grid row --> */}
        <div className="row mt-3">
            {/* <!-- Grid column --> */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            {/* <!-- Content --> */}
            <h6 className="text-uppercase fw-bold mb-4">
                <i className="fas fa-gem me-3"></i>Esto es
            </h6>
            <p>
                Esto es encuentra los mejores productos de Aliexpress y te brinda un
                catálogo de busqueda de productos seleccionados para que puedas comprar 
                direactamente, las compras se realizan por Aliexpress.
                
            </p>
            </div>

            {/* <!-- Grid column --> */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            {/* <!-- Links --> */}
            <h6 className="text-uppercase fw-bold mb-4">Ubicación</h6>
            <p><i className="fas fa-home me-3"></i> Colombia, Santander</p>
            <p>
                <i className="fas fa-envelope me-3"></i>
                Tienda Virtual
            </p>
            <p><i className="fas fa-envelope me-3"></i>Ciudad de Bucaramanga</p>
            </div>
            {/* <!-- Grid column --> */}
        </div>
        {/* <!-- Grid row --> */}
        </div>
    </section>
    {/* <!-- Section: Links  --> */}

    {/* <!-- Copyright --> */}
    <div className="text-center p-4" >
        © 2024 Copyright : 
        <a className="text-reset"  href="estoes.co">estoes.co</a>
    </div>
    {/* <!-- Copyright --> */}
    </footer>
    {/* <!-- Footer --> */}
        </>
    )

    }