import { useState } from "react"
import ListCookie from "./listCookie"
import "../styles/cookies.css"

export const Cookies = ()=>{
    const cookiesAceptadas = localStorage.getItem("cookiesAceptadas")
    let cookies = "rectangle glass rectact"

    if(cookiesAceptadas){
        cookies= "rectangle glass"  
    }
    let [rectact, setrectact] = useState(cookies)
    
    // console.log("cookiesAceptadas:",cookiesAceptadas,cookies)
    
    return(
    <>
    <div className={rectact}>
        <div className="avisocookies ">
            <h3 className="text-center fw-semibold" >Cookies</h3>
            <p className="text-center .fs-4 text ">Utilizamos cookies propias y de terceros para mejorar nuestros servicios.</p>
            <p className="text-center .fs-4 text ">Permiten que los sitios web recuerden información sobre tu visita, lo que puede hacer que sea más fácil volver a visitar los sitios y hacer que estos te resulten más útiles.</p>
            <br/>
            <button className="btn btn-primary position-relative start-50 translate-middle mt-1" 
            onClick={(e)=>{ setrectact("rectangle glass")
            localStorage.setItem('cookiesAceptadas',true)}}
            >De acuerdo</button>
            <br/>
            <a className="btn btn-secondary position-relative start-50 translate-middle mt-1 "
            href="avisocookies"
            >Aviso de Cookies</a>
        </div>
        <div className="fondo-aviso-cookies" id="fondo-aviso-cookies"></div>
        <a href="https://www.iubenda.com/privacy-policy/37458697" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">
        Politicas de privacidad
        </a>
    </div>
    </>
    )
}