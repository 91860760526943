import React from 'react';
import ReactDOM from 'react-dom';
// import ProductsList from '../src/componentes/ProductsList';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '../src/styles/index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ToggleTheme from './ToggleTheme';
import LeerDriveJson from './componentes/LeerDriveJson';
import { Avisocookies } from './componentes/avisocookies';
// import { publicDrive } from './componentes/PublicDrive';

const router = createBrowserRouter([
  {
    path: "/",
    element:<ToggleTheme/>,
  },
  {
    path: "/GetEstoEsPrd",
    // element:<pub  licDrive/>
    element:<LeerDriveJson/>
  },
  {
    path: "/avisocookies",
    element:<Avisocookies/>
  },
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
);