import * as React from "react";
import ProductCard from "./ProductCard";

export default function ProductsList({items}) {
  // const list = items?.map((element) =>{
  //   const {idprd,desc,precio,rating,vendidos,imageUrl,url} = element;
  //   return <ProductCard key={idprd} desc={desc} precio={precio} rating={rating}
  //               vendidos={vendidos} imageUrl ={imageUrl} url={url}/>
  // });

  return (
    <>
    {/* <div>{list}</div> */}
      <div>
        {items.map((element) => (
          <ProductCard key={element.id} {...element} />
        ))}
      </div>
    </>
  );
}