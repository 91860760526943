import { useState } from "react";
//import BorbujaDown from "../componentes/icons/BorbujaDown";
import BorbujaUp from "../componentes/icons/BorbujaUp";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '../styles/UpDown.css'
import { Styles } from "../componentes/Styles";
//import Switch from "../componentes/Switch";

// Window.scroll({
//     top:100,
//     button:100,
//     behavior: 'smooth'
// })

// console.log(window.scrollY)

const CambioDir =()=>{
    const [Rota, setRota]= useState('rotate(90)');
    const [ubicar,SetUbicar] = useState(0);
    const [modifTex,SetModifTex] = useState('final');
    // const {onToggle} = Switch;
    // const theme = Styles('light');
    // const {lightTheme, Darktheme,isDarktheme,theme } = Styles('dark')
    
    // toggle(setIsToggled(!isToggled));
    // console.log('tema es oscuro ?:',isDarktheme,onToggle)

    window.addEventListener('scroll', () => {
        // Obtener la posición actual de desplazamiento
        const scrollPosition = Math.round(
          // window.innerHeight + window.pageYOffset //deprecaried
          window.innerHeight + window.scrollY
        );
        // Obtener la altura total del documento
        const documentHeight = document.documentElement.scrollHeight;
        // Verificar si se ha alcanzado el final de la página
        if (scrollPosition > documentHeight*(0.03)) {
          setRota("rotate(270)");
          SetUbicar(0);
          SetModifTex('inicio');
        } else {
          setRota("rotate(90)");
          SetUbicar(document.documentElement.scrollHeight);
          SetModifTex('final');
        }
    })

    return {Rota,ubicar,modifTex}
}
 
function UpDown() {
    const {Rota,ubicar,modifTex} = CambioDir()
    return (
      <div className="UpDownClass">
        <div className="burbDown" 
        id="cambioDir"
        style={{ cursor: 'pointer' }} 
        onClick={(e)=>window.scroll(0,ubicar)}
        >
        <h3 className="neon" >{modifTex}</h3>
    {BorbujaUp("", "", Rota ,'#90a4ae')}
        </div>
      </div>
    );
    
}
export default UpDown;