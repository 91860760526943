function YoutubeIcon(ancho='64px',alto='64px') {
return (
  <svg
    width={ancho}
    height={ancho}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g transform="translate(0 -1028.4)">
        {" "}
        <g fill="#2980b9">
          {" "}
          <path
            d="m12 1039.4c-3.0105 0-5.2407 0.1-6.7256 0.2-0.4788 0.1-0.9101 0.3-1.2672 0.6-0.357 0.3-0.6012 0.7-0.7148 1.1-0.211 0.9-0.2924 2.2-0.2924 4 0 1.9 0.0814 3.2 0.2924 4.1 0.1055 0.5 0.3578 0.8 0.7148 1.2 0.3652 0.3 0.764 0.5 1.2347 0.5 1.493 0.2 3.7476 0.3 6.7581 0.3 3.01 0 5.265-0.1 6.758-0.3 0.471 0 0.91-0.2 1.267-0.5 0.357-0.4 0.569-0.7 0.683-1.2 0.211-0.9 0.292-2.2 0.292-4.1 0-1.8-0.081-3.1-0.292-4-0.106-0.4-0.318-0.8-0.683-1.1-0.357-0.3-0.796-0.5-1.267-0.6-1.493-0.1-3.748-0.2-6.758-0.2z"
            fill="#c0392b"
          ></path>{" "}
          <path
            d="m12 1038.4c-3.0105 0-5.2407 0.1-6.7256 0.2-0.4788 0.1-0.9101 0.3-1.2672 0.6-0.357 0.3-0.6012 0.7-0.7148 1.1-0.211 0.9-0.2924 2.2-0.2924 4 0 1.9 0.0814 3.2 0.2924 4.1 0.1055 0.5 0.3578 0.8 0.7148 1.2 0.3652 0.3 0.764 0.5 1.2347 0.5 1.493 0.2 3.7476 0.3 6.7581 0.3 3.01 0 5.265-0.1 6.758-0.3 0.471 0 0.91-0.2 1.267-0.5 0.357-0.4 0.569-0.7 0.683-1.2 0.211-0.9 0.292-2.2 0.292-4.1 0-1.8-0.081-3.1-0.292-4-0.106-0.4-0.318-0.8-0.683-1.1-0.357-0.3-0.796-0.5-1.267-0.6-1.493-0.1-3.748-0.2-6.758-0.2z"
            fill="#e74c3c"
          ></path>{" "}
          <path
            d="m6.5 1l1.5 5v3h1v-3l1.5-5h-1l-1 3.3438-1-3.3438h-1zm6 2c-0.828 0-1.5 0.6716-1.5 1.5v3c0 0.8284 0.672 1.5 1.5 1.5s1.5-0.6716 1.5-1.5v-3c0-0.8284-0.672-1.5-1.5-1.5zm2.5 0v0.3438 1.6562 2.75c0 0.3821 0.036 0.664 0.094 0.8438 0.093 0.277 0.325 0.4063 0.656 0.4062 0.359 0.0001 0.869-0.2232 1.25-0.6875v0.25 0.4375h1v-6h-1v4.5 0.0938c-0.216 0.322-0.587 0.5-0.781 0.5-0.13 0-0.197-0.0852-0.219-0.25-0.007-0.015 0-0.1591 0-0.4063v-2.4375-1.6562-0.3438h-1zm-2.5 1c0.276 0 0.5 0.2239 0.5 0.5v3c0 0.2761-0.224 0.5-0.5 0.5s-0.5-0.2239-0.5-0.5v-3c0-0.2761 0.224-0.5 0.5-0.5z"
            transform="translate(0 1028.4)"
            fill="#202020"
          ></path>{" "}
          <g fill="#ecf0f1">
            {" "}
            <rect height="1" width="4" y="1040.4" x="4.5"></rect>{" "}
            <rect height="7" width="1" y="1041.4" x="6"></rect>{" "}
            <path d="m8 1042.4v0.3 1.7 2.7c0 0.4 0.0362 0.7 0.0938 0.9 0.0934 0.2 0.3256 0.4 0.6562 0.4 0.3594 0 0.8691-0.3 1.25-0.7v0.2 0.5h1v-6h-1v4.5 0.1c-0.2156 0.3-0.5872 0.5-0.7812 0.5-0.1294 0-0.1972-0.1-0.2188-0.3-0.0072 0 0-0.2 0-0.4v-2.4-1.7-0.3h-1z"></path>{" "}
            <path
              d="m12 12v2 6h1v-0.438-0.25c0.381 0.465 0.891 0.688 1.25 0.688 0.331 0 0.563-0.129 0.656-0.406 0.058-0.18 0.094-0.462 0.094-0.844v-1.75-1.75c0-0.382-0.036-0.664-0.094-0.844-0.093-0.277-0.325-0.406-0.656-0.406-0.359 0-0.869 0.223-1.25 0.688v-0.688-2h-1zm1.781 2.906c0.13 0 0.197 0.085 0.219 0.25 0.007 0.015 0 0.159 0 0.406v1.438 1.438c0 0.247 0.007 0.391 0 0.406-0.022 0.165-0.089 0.25-0.219 0.25-0.194 0-0.565-0.178-0.781-0.5v-0.094-3.094c0.216-0.322 0.587-0.5 0.781-0.5z"
              transform="translate(0 1028.4)"
            ></path>{" "}
            <path
              d="m17.5 14c-0.828 0-1.5 0.672-1.5 1.5v3c0 0.828 0.672 1.5 1.5 1.5s1.5-0.672 1.5-1.5v-0.5h-1v0.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-0.5-1h1 1v-1.5c0-0.828-0.672-1.5-1.5-1.5zm0 1c0.276 0 0.5 0.224 0.5 0.5v0.5h-1v-0.5c0-0.276 0.224-0.5 0.5-0.5z"
              transform="translate(0 1028.4)"
            ></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
}
export default YoutubeIcon;