import React, { Component ,useRef } from "react";
import Slider from "react-slick";
import ProductCard from "./ProductCard";
import "../styles/carrousel.css"



export function Carrusell({carrusel}) {
  const list = carrusel?.map((element) => {
    const { idprd, desc, precio, rating, vendidos, imageUrl, url } = element;
    return (
      <ProductCard
        idprd={idprd}
        desc={desc}
        precio={precio}
        rating={rating}
        vendidos={vendidos}
        imageUrl={imageUrl}
        url={url}
      />
    );
  });

  const settings = {
    className: "center bg-gradient",
    centerMode: true,
    dots: true,
    infinite: true,
    focusOnSelect: true,
    centerPadding: "150px",
    slidesToShow: 3, // Valor por defecto para pantallas grandes
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // Pantallas menores a 1024px
        settings: {
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 2,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 600, // Pantallas menores a 600px
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll:1,
          centerPadding: "20px",
        },
      },
    ],
  };
  return (
    <div className="carousel center-align">
      <Slider {...settings}>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[0]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[0]?.props.imageUrl} alt="" className="imgItem shadow-lg"  />
          {/* <div className="d-grid gap-2 col-6 mx-auto visitarPrd"> */}
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[0]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
          {/* </div> */}
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[1]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[1]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
          {/* <div className="d-grid gap-2 col-6 mx-auto visitarPrd"> */}
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[1]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
          {/* </div>           */}
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[2]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[2]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
          {/* <div className="d-grid gap-2 col-6 mx-auto visitarPrd"> */}
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[2]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
          {/* </div>           */}
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[3]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[3]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
          {/* <div className="d-grid gap-2 col-6 mx-auto visitarPrd"> */}
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[3]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
          {/* </div>           */}
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[4]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[4]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
          {/* <div className="d-grid gap-2 col-6 mx-auto"> */}
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[4]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
          {/* </div>           */}
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[5]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[5]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[5]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[6]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[6]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[6]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[7]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[7]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[7]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
        </div>
        <div className="carousel-item">
          <h2 className="subtitulo">{list[8]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[8]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[8]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
        </div> 
        <div className="carousel-item">
          <h2 className="subtitulo">{list[9]?.props.idprd}</h2>
          <div className="linea-division"></div>
          <img src={list[9]?.props.imageUrl} alt="" className="imgItem shadow-lg" />
            <a className="btn btn-danger visitarPrd"
              type="button"
              href={list[9]?.props.url}
              target="_blank"
              rel="noopener noreferrer"
            >Visitar Producto</a>
        </div>                         
      </Slider>
    </div>
  );
}
