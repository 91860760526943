import React, { useState } from "react";
import "../styles/Switch.css"

function Switch({toggle}) { // ToggleTheme entre {} representa funcion
  const [isToggled, setIsToggled] = useState(true);
  const onToggle = () => {
    toggle(setIsToggled(!isToggled));
  };

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="switch" />
    </label>

  );
}
export default Switch;
