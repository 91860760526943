import { useState } from "react";


export const Styles =(inicialState='dark')=>{
  const [theme, Settheme]=useState(inicialState);
  const isDarktheme = theme ==='dark';
  // console.log(theme)
  const toggle =()=>{
    Settheme(isDarktheme?"light":"dark")
  }
    // Estilos personalizados:
    // const texsec={ color: `${theme.texsec} `};
    // const bar ={  color: `${theme.cardtext}`, 
    //                  backgroundColor: `${theme.barbg}`}; 

    const lightTheme ={
      body:"#fff",
      title:"#1c1c1c",
      subtitle:"#333",
      fill:"#263238",
      texsec: "#495057",
      cardtext:"#000",
      cardbg:"rgb(218, 218, 218)",
      barbg:"rgb(218, 218, 218)"
    }
    const Darktheme ={
      body:"#263238",
      title:"#fff " ,
      subtitle:"#b6b6b6",
      fill:"#fff",
      texsec: "#fff",
      cardtext:"#fff",
      cardbg:"#6c757d",
      barbg:"#6c757d"
    }


    return({
      theme,
      Settheme,
      toggle,
      lightTheme,
      Darktheme,
      isDarktheme,
      // texsec,
      // bar
      // StyledApp
  })
}

