import React, { useEffect, useState } from 'react';
import { useTheme } from "styled-components";  // para usar temas creados
import  {useProducts}  from "./hook/useProducts";
// import {products} from '../products'
import ProductsList from "./ProductsList";
import { BarraNav } from './header/header';
import { Footer } from './header/footer';
import { Carrusell } from './Carrusell';
import AboutUs from '../pages/AboutUs'
import { Cookies } from './Cookies';
// import { Styles } from "./Styles";


function descendente(a,b){return (b.idprd-a.idprd)}; //descendiente para sort
function ascendente(a,b){return (a.idprd-b.idprd)}; //ascendente para sort

const getFilteredItems = (query, items) => {
    if (!query) {
      return items.sort(descendente);
    } if (!isNaN(query)){ // isNAN indica sí query es o no un número.
        items.sort(ascendente);
        return (
            // indica sí query esta incluido en items convirtiendo en string
            items.filter((items) => String(items.idprd).includes(String(query))))
    } else{
        //indica sí titulo incluye lo que viene en query sin importar mayuscula o minuscula
        return items.filter((items)=>items.desc.toUpperCase().includes(query.toUpperCase()))
    }
  };
  
//   let ValT ='';
//   const Valin =(Val)=>{
//     if(Val != null){
//         ValT= ValT.concat(Val);
//     } else{
//         ValT=ValT.slice(0,-1)
//     }
//     // console.log('Valin:',ValT);
// }

export const  SearchBar=()=> {
    // console.log('estoy en Search')
    const [query, setQuery] =useState("");
    const theme = useTheme(); //utiliza el valor de hook de useState() de styled
    const jsonData = useProducts(); //hook de productos
    const [products1, setProducts] = useState([]);// Estado para almacenar el array de products
    const [showCarrusel, setShowCarrusel] = useState(true);
    const [showAll, setShowAll]= useState(false);
    // console.log('jsonData:',jsonData)
    useEffect(()=>{ 
        const fetchJson =async()=>{
            const data = await jsonData;
            if(data != undefined){
                // console.log('data en SearchBar',data)
                 setProducts(data)
                return data;
            }
        }
        fetchJson()
    },[jsonData])
    
    const filteredItems = getFilteredItems(query, products1);
    //  console.log('filteredItems: ',filteredItems,filteredItems.length==0)
    //  console.log('showCarrusel: ',showCarrusel)
    // filteredItems.sort(descendente)
            
    // console.log('filterdItems',filteredItems[0].idprd); 
    // console.log('filterdItems',filteredItems.sort(descendente)); 
 
    // Estilos personalizados:
    // const {texsec,bar} = Styles()
    const texsec={ color: `${theme.texsec} `};
    return (
        <>
        <div className="containerApp">
            <BarraNav/>
            {showCarrusel && <Carrusell carrusel = {products1}/>}
            <br/>
            <hr className="bar"></hr>
            <form className="p-3 container-fluid search " role="search" >
                <h3 style={texsec} className="p-2 txtsearch" > Buscador</h3>
                <input className="form-control me-5 bar fs-2" type="search" 
                placeholder="Escriba el número o palabra clave del producto..." aria-label="Search"
                onChange={(e) =>{   setQuery(e.target.value)
                    e.target.value==""?setShowCarrusel(true):setShowCarrusel(false)
                }}
                ></input>
            </form>
            <Cookies/>  
            <hr className="bar"></hr>          
            <br/>
            <h1 style={texsec} className='text-center '>🐶</h1>
            <h1 style={texsec} className='text-center '> 👉▶︎<button className='btn btn-success'
            onClick={(e)=>{
                showAll==false?setShowAll(true):setShowAll(false);
                console.log('clic',showAll)}
            }>{showAll==false?
            <h3>Ver todos los productos</h3>
            :<h3>dejar de mostrar</h3>}</button>◀︎👈 </h1>
            <br/>
                
            {/* <ProductsList  items = {filteredItems }/> */}
            {
                showAll==true?
                <ProductsList  items = {filteredItems }/>
                :filteredItems.length==0?
                <h1 style={texsec} className='text-center'>No ha sido posible encontrar el producto con el criterio de busqueda solicitado 🙁</h1>
                :showCarrusel==false?
                <ProductsList  items = {filteredItems }/>
                :<AboutUs/>
            }

            <Footer/>
        </div>
        </>
    )
}