import React, { useEffect, useState } from 'react';
import { useProducts } from './hook/useProducts';

const LeerDriveJson = () => {
  // Obtener la data de productos utilizando el hook useProducts
  const jsonData = useProducts();

  // Estado para almacenar el array de salida
  const [salida, setSalida] = useState([]);

  useEffect(() => {
    // Función asincrónica para esperar a que se resuelva la promesa y actualizar el estado
    const esperaData = async () => {
      const data = await jsonData; // Esperar a que se resuelva la promesa
      setSalida(data); // Actualizar el estado con el array de datos
      // console.log('LeerDriveJson es:', data); // Mostrar en consola el array de datos
      return data;
    };

    esperaData(); // Llamar a la función para obtener y actualizar los datos
  }, [jsonData]);

  console.log('Salida:', salida); // Mostrar en consola el estado de salida

  return (
    <div>
      {/* Mostrar el array de salida si existe */}
      {salida && <pre>{JSON.stringify(salida, null, 2)}</pre>}
    </div>
  );
};

export default LeerDriveJson;
