import * as React from "react";
import { useTheme } from "styled-components";

export default function  ProductCard ({idprd,desc,precio,rating,vendidos,imageUrl,url}){
const theme = useTheme();
const card={ 
  color: `${theme.cardtext}`, 
  backgroundColor: `${theme.cardbg}`
};    
  return(
    <>
    <div style={card} className="card">
      <img  src={imageUrl}></img>
      <div className="conten">
        <h3>{idprd}</h3>
        {/* <h2>{category}</h2> */}
        <p>{desc}</p>
        <h3>{precio}</h3>
        <p>más de {vendidos}</p>
        <p>calificación: {rating} de 5</p>
      </div>
      {/* <button type="button" class="btn btn-success btn-lg">Realizar Comprar</button> */}
      <div className="d-grid gap-2 col-6 mx-auto">
        <a className="btn btn-danger" 
        type="button" href={url} target="_blank"  rel="noopener noreferrer">
          Visitar Producto
        </a>
      </div>
    </div>
    </> 
  )
}