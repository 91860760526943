
function BorbujaUp(ancho='64px',alto ='64px',rotar='rotate(270)',tema='#78909c') {
    return (
      //Copied from https://www.svgrepo.com/svg/90781/next
      <svg
        height="64px"
        width="64px"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 463.361 463.361"
        transform={rotar}
      >
        <filter id="shadow-filter">
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
        </filter>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <path
              //   style="fill:#26a69a;"
              style={{ fill: tema }}
              d="M306.224,164.008c-2.767,2.51-6.465,3.735-10.183,3.373L28.528,141.324 c-3.022-0.294-6.022,0.475-8.528,2.116v180.423c0,3.729,1.572,7.284,4.329,9.794c2.449,2.229,5.632,3.45,8.915,3.45 c0.413,0,0.827-0.019,1.242-0.058l267.597-25.196c0.415-0.039,0.829-0.058,1.242-0.058c3.282,0,6.466,1.221,8.915,3.45 c2.757,2.51,4.329,6.066,4.329,9.794v38.921l120.196-120.196c-0.615-1.993-1.705-3.87-3.282-5.448L316.569,121.402v16.797 c0,3.736-1.578,7.298-4.345,9.808c-0.519,0.471-1.077,0.886-1.656,1.265v4.927C310.568,157.935,308.991,161.497,306.224,164.008z"
            ></path>{" "}
            <path
              //   style="fill:#00695c;"
              style={{ fill: tema }}
              d="M316.569,99.402l122.913,122.913c5.172,5.172,5.172,13.558,0,18.73L316.569,363.959v-38.921 c0-3.729-1.572-7.284-4.329-9.794c-2.449-2.229-5.632-3.45-8.915-3.45c-0.413,0-0.828,0.019-1.242,0.058L34.486,337.049 c-0.415,0.039-0.829,0.058-1.242,0.058c-3.282,0-6.465-1.221-8.915-3.45c-2.757-2.51-4.329-6.066-4.329-9.794V138.506 c0-3.736,1.578-7.298,4.345-9.808c2.448-2.221,5.624-3.436,8.899-3.436c0.427,0,0.856,0.021,1.285,0.063l267.512,26.057 c0.429,0.042,0.857,0.062,1.285,0.062c3.274,0,6.451-1.215,8.899-3.436c2.767-2.51,4.345-6.073,4.345-9.808V99.402 M316.564,79.402 c-2.576,0-5.175,0.498-7.649,1.523c-7.474,3.096-12.346,10.388-12.346,18.478v31.351L36.467,105.419 c-1.066-0.104-2.151-0.157-3.224-0.157c-8.276,0-16.209,3.063-22.338,8.624C3.975,120.174,0,129.148,0,138.506v185.357 c0,9.338,3.961,18.299,10.866,24.585c6.135,5.584,14.082,8.66,22.377,8.66c1.037,0,2.087-0.049,3.119-0.147l260.206-24.5v31.499 c0,8.089,4.873,15.382,12.346,18.478c2.474,1.025,5.073,1.523,7.649,1.523c5.205,0,10.32-2.032,14.146-5.858l122.913-122.913 c6.279-6.279,9.737-14.627,9.737-23.507s-3.458-17.228-9.737-23.507L330.711,85.26C326.885,81.434,321.769,79.402,316.564,79.402 L316.564,79.402z"
            ></path>{" "}
          </g>{" "}
        </g>
      </svg>
    );    
}
export default BorbujaUp;