
import React, { useEffect, useState } from 'react';

export const useProducts = async () => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const obtenerJson = async () => {
      try {
        const fileUrl = "https://server-json-drive-ad5yf2721-erik2807.vercel.app/GetEstoEsPrd";
        const response = await fetch(fileUrl);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error al obtener el archivo:', error);
      }
    };
    obtenerJson();
  }, []);
  // const mostrar = jsonData==null?'procesando..':jsonData
  
  if(!(jsonData==null)){
    const jsonDataSalida = jsonData
    // console.log('JsonData en useProduct:',jsonDataSalida)
    return jsonDataSalida
  }
};

// const products = productsData || require('../../DB/productos.json');

// export default products;
