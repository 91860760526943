import { useTheme } from "styled-components";  // para usar temas creados
import InstaIcon from "../icons/InstaIcon";
import FacebIcon from "../icons/FacebIcon";
import TikTokIcon from "../icons/TiktokIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import { navbar } from "@bootstrap-styled/css-mixins/lib/navbar";

export const BarraNav =()=>{
    const theme = useTheme(); //utiliza el valor de hook de useState() de styled
  // Estilos personalizados:
  // const {texsec,bar} = Styles()
  const bar = { color: `${theme.cardtext}`, backgroundColor: `${theme.barbg}` };
  

  return(
  <>
  <nav style={bar} className="navbar navbar-expand-lg bar">
      <div style={bar} className="container">
          {/* <a className="navbar-brand" href="#">LOGO</a> */}
          <img  src='img/logo1.jpg' className="navbar-brand logobar"></img>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div style={bar}  
          className="collapse navbar-collapse " 
          id="navbarSupportedContent">
            <a style={bar} className="btn btn-light  p-1 m-1"
                href="https://www.tiktok.com/@esto_ess?_t=8ip1iNqdyMP&_r"
                target="_blank"  rel="noopener noreferrer"
                >TikTok {TikTokIcon('20px','20px')}</a>
            <a style={bar} className="btn btn-light p-1 m-1"
                href="https://youtube.com/@Estooess?si=ovah3lGH-iwpluO7"
                target="_blank"  rel="noopener noreferrer"
                >Youtube {YoutubeIcon('20px','20px')}</a>
            <a style={bar} className="btn btn-light p-1 m-1"
                href="https://www.instagram.com/estoo_ess/"
                target="_blank"  rel="noopener noreferrer"
                >Instagram {InstaIcon('20px','20px')}</a>
            <a style={bar} className="btn btn-light p-1 m-1" 
                href="https://www.facebook.com/BasicasSoluciones?mibextid=ZbWKwL"
                target="_blank"  rel="noopener noreferrer"
                >facebook {FacebIcon('20px','20px')}</a>
            <div style={bar} className="navbar-brand font-italic MARG1 " >
               <a>productos de Aliexpress </a>
               <img  src='img/logoAliexpress.png' className="logobar"></img>
            </div>
          </div>
      </div>
  </nav>
  <br/>
  </>
  )
}