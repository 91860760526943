import { useTheme } from "styled-components";  // para usar temas creados
import  "../styles/aboutUs.css";
export default function AboutUs() {
    const theme = useTheme(); //utiliza el valor de hook de useState() de styled
    return (
      <>
        <head>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="" />
          <meta name="author" content="" />
          <script
            src="https://use.fontawesome.com/releases/v6.3.0/js/all.js"
            crossorigin="anonymous"
          ></script>
          {/* Simple line icons */}
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css"
            rel="stylesheet"
          />
          {/* Google fonts */}
          <link
            href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic"
            rel="stylesheet"
            type="text/css"
          />
        </head>
        <div>
          {/* <!-- sección1--> */}
          <section className="content-section bg-secondary-subtle " id="about">
            <div className="container px-4 px-lg-5 text-center">
              <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-10">
                <br/>
                <br/>
                  <h2>¿Quienes somos?</h2>
                  <p className="lead mb-5">
                    <strong>Esto es</strong> una empresa que te permite buscar
                    todo tipo de productos de Aliexpres, hemos clasificado los
                    productos mas solicitados y de gran utilidad, en nuestros
                    canales y redes sociales puedes ver una descripción y video
                    del producto antes de comprarlo, de esa manera puedes
                    entender y reconocer mejor el producto entendiendo las
                    características antes de realizar la compra.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- seccion2--> */}
          <section className="content-section bg-dark-subtle" id="about">
            <div className="container px-4 px-lg-5 text-center">
              <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-10">
                <br/>
                <br/>
                  <h2>¿Como encuentro mi producto?</h2>
                  <p className="lead mb-5">
                  <strong>Esto es</strong> muy fácil, en el buscador debes escribir el
            número del producto que aparece en los videos y te aparecerán el
            producto, tambíen puedes buscarlo con el nombre o alguna referencia
            que tengas del producto y filtrará los elementos que coincidan con
            lo que estás buscando, es posible que algunos productos el precio no
            coincida porque existe alguna promoción o hubo un cambio en la
            economía, por lo que se recomienda revisar hacer clic en{" "}
            <em>visitar producto</em> para una revisión más detallada.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- sección3--> */}
          <section className="content-section bg-light callout" id="about">
            <div className="container px-4 px-lg-5 text-center">
              <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-10">
                  <h2 >¿Es seguro comprar en <strong>Esto es</strong>?</h2>
                  <p className="lead mb-6">
                  Sí,<strong> Esto es</strong> seguro porque las compras se realizan
            directamente con la empresa certificada y encargada de distribuir
            los productos, en este caso Aliexpress nuestro objetivo es
            clasificar y redireccionar a los mejores productos del mercado y que
            podrían ser de tu interés, pero por ningún motivo vamos a solicitar
            datos personales aquí siempre será desde Aliexpress.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
}